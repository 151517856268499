import { Button, Form, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { CompareRule, GroupItem as GroupItemForm, RequiredRule, SimpleItem } from 'devextreme-react/form';
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { useAuth } from "../../../../contexts/auth";
import { AuthApiUrl } from "../../../../environment/routeSettings";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { VMChangePassword, VMUserProfileDetails } from "../../../../types/identity/dto/identityDTO";
import { SignOnMethod } from "../../../../types/identity/identityTypes";
import { RequestErrorHandling, TesPost } from "../../../../utils/rest";
import design from "./password.module.scss";

// props
interface IPros {
    initData: VMUserProfileDetails,
    setInitData: React.Dispatch<React.SetStateAction<any>>;
}

const Password = (props: IPros) => {

    const validationGroupRef = useRef<ValidationGroupRef>(null);
    const [data, setData] = useState<VMChangePassword>(new VMChangePassword());
    const [passMode, setPassMode] = useState<'email' | 'password' | 'search' | 'tel' | 'text' | 'url'>('password');
    const { activeLoading } = useAuth();
    function onValueChanged(name: string, value: any) {
        setData({ ...data, [name]: value })
    }

    function passwordComparison() {
        return data.password;
    }

    async function onReset() {
        try {
            var validationRes = validationGroupRef.current?.instance().validate();
            if (validationRes?.isValid) {
                const regexString = (props.initData?.generalSetting && props.initData?.generalSetting?.passwordExpression)
                    ? props.initData?.generalSetting?.passwordExpression
                    : '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=\\[\\]{};:\'",.<>\\/?`~|])[A-Za-z\\d!@#$%^&*()_+\\-=\\[\\]{};:\'",.<>\\/?`~|]{8,}$';

                const cleanRegexString = regexString.replace(/^\/|\/$/g, "");
                const regex = new RegExp(cleanRegexString);

                if (!(regex.test(data?.password ?? ""))) {
                    notify(t("passwordIsNotComplex"), "error", 5000);
                    return;
                }
                var postObj: VMChangePassword = {
                    confirmPassword: data.confirmPassword,
                    password: data.password,
                    username: props.initData.userName
                }
                if (activeLoading) activeLoading(true);
                const res = (await (TesPost(AuthApiUrl() + "/api/User/ChangePasswordUserProfile", postObj, true))) as RequestResponseResult<null>
                if (res.responseCode === ResponseCode.OK) {
                    if (activeLoading) activeLoading(false);
                    notify(t("dataSuccessfullyUpdated"), "success", 5000);
                } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
                    if (activeLoading) activeLoading(false);
                    notify(t("customerNotPermitted"), "error", 5000);
                } else if (res.responseCode === ResponseCode.PasswordIsNotComplex) {
                    if (activeLoading) activeLoading(false);
                    notify(t("passwordIsNotComplex"), "error", 5000);
                } else if (res.responseCode === ResponseCode.UsernamePasswordNotValid) {
                    if (activeLoading) activeLoading(false);
                    notify(t("usernamePasswordNotValid"), "error", 5000);
                }
                if (activeLoading) activeLoading(false);
                await RequestErrorHandling(res);;
            }
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    return (
        <React.Fragment>
            {props.initData.signOnMethod !== SignOnMethod.AzureSingleSignOn ?
                <div className="row" style={{ marginTop: 20, padding: 10 }}>
                    <Form colCount={1}>
                        <GroupItemForm caption={t("resetPassword")}
                            name={t("resetPassword")}
                            visible={true}
                            colSpan={1}
                        >
                            <ValidationGroup
                                ref={validationGroupRef}
                            >
                                <Form
                                    colCount={3}>
                                    <SimpleItem  >
                                        <TextBox
                                            label={t('newPassword')}
                                            labelMode='static'
                                            value={data.password}
                                            onValueChange={e => onValueChanged("password", e)}
                                            className={design.passwordField}
                                            mode={passMode}
                                        >
                                            <i
                                                className={passMode === "text" ? `${"fa-regular fa-eye-slash fa-xl"} ${design.eyeIcon}` : `${"fa-regular fa-eye fa-xl"} ${design.eyeIcon}`}
                                                onClick={() => passMode === "text" ? setPassMode("password") : setPassMode("text")}
                                            />
                                            <Validator>
                                                <RequiredRule message={t('passwordIsRequired')} />
                                            </Validator>
                                        </TextBox>
                                    </SimpleItem>
                                    <SimpleItem >
                                        <TextBox
                                            label={t('confirmNewPassword')}
                                            labelMode='static'
                                            value={data.confirmPassword}
                                            onValueChange={e => onValueChanged("confirmPassword", e)}
                                            className={design.passwordField}
                                            mode={passMode}
                                        >
                                            <i
                                                className={passMode === "text" ? `${"fa-regular fa-eye-slash fa-xl"} ${design.eyeIcon}` : `${"fa-regular fa-eye fa-xl"} ${design.eyeIcon}`}
                                                onClick={() => passMode === "text" ? setPassMode("password") : setPassMode("text")}
                                            />
                                            <Validator>
                                                <RequiredRule message={t('passwordIsRequired')} />
                                                <CompareRule message={t('passwordAndConfirmPasswordDoNotMatch')} comparisonTarget={passwordComparison} />
                                            </Validator>
                                        </TextBox>
                                    </SimpleItem>
                                </Form>
                            </ValidationGroup>
                        </GroupItemForm>
                    </Form>
                    <Button
                        className='tes-modal-btn-cancel rightColumn'
                        onClick={onReset}
                        text={t('reset')}
                    />
                </div>
                :
                <div className='row' style={{ padding: 5 }}>
                    <p style={{ fontWeight: 'bold' }}>{t("noPermissionContactAdmin")}</p>
                </div>
            }
        </React.Fragment>
    );
};
export default Password;