//imports
import { ContextMenu, DropDownButton, Lookup, Popup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  StateStoring,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../components/permission/permision";
import TesReportViewer from "../../../components/reportViewer/ReportViewer";
import { MaintenancePermissions } from "../../../constants/Permissions";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { MaintenanceApiUrl, ReportApiUrl } from "../../../environment/routeSettings";
import { GridType } from "../../../types/general/enums/generalEnums";
import { GridSetting } from "../../../types/general/generalTypes";
import { ClientReport, ReportLocation, UiReportRequest } from "../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { useScreenSize } from "../../../utils/media-query";
import { TesPost, TesPut } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import "./jobs.scss";

const dataSource = new CustomStore({
  load: async (loadOption) => {
    return await TesPut(
      `${MaintenanceApiUrl()}/api/Jobs/LazyLoading/${localStorage.getItem(
        "selectedCustomerId"
      )}`,
      JSON.stringify(loadOption),
      true
    );
  },
});

const Jobs = () => {
  //#region consts
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
  const [lstSelectedIds, setLstSelectedIds] = useState<string[]>([]);
  const [initDataReports, setInitDataReports] = useState<ClientReport[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const dropDownOptions = { width: 215 };
  const { is2xLarge } = useScreenSize();
  const [reportPopupHeight, setReportPopupHeight] = useState<string>("80%")
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { generalSetting, customerDivisions } = useClientSetting();
  //#endregion consts

  useEffect(() => {
    if (is2xLarge) { setReportPopupHeight("55%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataReports();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToJobDetails = useCallback(() => {
    history("/maintenance/jobDetails/AddNew");
  }, [history]);

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/maintenance/jobDetails/${e.data.id}`, "_blank");
      } else {
        history(`/maintenance/jobDetails/${e.data.id}`);
      }
    }
  }

  function onOpenReport(d: any) {
    if (dataGridRef.current?.instance().getSelectedRowKeys().length === 0) {
      notify(t('pleaseSelectSomeData'), 'warning', 5000);
      return;
    }
    setShowReportModal(true);
    setSelectedReport(d.itemData);
    setLstSelectedIds(dataGridRef.current?.instance().getSelectedRowKeys().map(x => x.id) as string[]);
  }


  function onCloseReportModal() {
    setShowReportModal(false);
    dataGridRef.current?.instance().deselectAll();
  }

  async function getInitialDataReports() {
    try {
      var postObj: UiReportRequest = {
        customerId: localStorage.getItem("selectedCustomerId")!,
        lstLocations: [
          reportLocations.Maintenance_jobs,
        ]
      }
      var res: ClientReport[] = await TesPost(
        ReportApiUrl() +
        "/api/ClientReports/GetPageReports",
        postObj,
        true
      );
      var validationReport = new ClientReport()
      validationReport.id = 'validationReport'
      validationReport.name = t('validationReport')
      var reportLoc = new ReportLocation()
      reportLoc.name = reportLocations.Maintenance_jobs
      validationReport.reportLocations = [reportLoc]
      res.push(validationReport)
      setInitDataReports(res);
    } catch (ex) {
      notify(t("errorInFetchReports") + ex, "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[
        MaintenancePermissions.Maintenance_D_Job,
        MaintenancePermissions.Maintenance_E_Job,
        MaintenancePermissions.Maintenance_R_Job,
        MaintenancePermissions.Maintenance_V_AllJob,
        MaintenancePermissions.Maintenance_CompleteJob,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, `/maintenance/jobDetails/`)}
          />
          <h2 className={"content-block"}>{t("jobs")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>
                  {/* <Button
                            icon="map"
                        /> */}
                  {/* <Button
                                onClick={() => getInitDataGisMap()}
                                icon="map"
                            />
                               <Button
                                onClick={() => getInitDataGisMap()}
                                icon="map"
                            />
                               <Button
                                onClick={() => getInitDataGisMap()}
                                icon="map"
                            /> */}
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.Jobs}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />
                  <DropDownButton
                    style={{ marginLeft: 10 }}
                    icon="fa-solid fa-chart-line"
                    hint={t('report')}
                    items={SortObjectByPropName(initDataReports?.filter((x: ClientReport) => [reportLocations.Maintenance_jobs].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")}
                    dropDownOptions={{ width: MaxDropdownItemWidthCalculator(SortObjectByPropName(initDataReports?.filter((x: ClientReport) => [reportLocations.Maintenance_jobs].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")) }}
                    displayExpr="name"
                    onItemClick={(d) => onOpenReport(d)}
                  />

                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[MaintenancePermissions.Maintenance_D_Job]}
                    hasFeedBackElement={true}
                  >
                    <Button
                      onClick={navigateToJobDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("job")}
                    />
                  </Permission>
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                columnAutoWidth={gridSetting.horizontalScrolling}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.Intersection.toString()}
                  savingTimeout={500}
                />
                <Summary>
                  <TotalItem column="GeoId" summaryType="count" />
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50, 100]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="id"
                  visible={false}
                  caption={t("id")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="jobId" caption={t("jobId")} defaultSortOrder="asc">
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="jobTypeName" caption={t("jobType")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="jobStatusName" caption={t("jobStatus")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="assignDate"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("assignDate")}
                  selectedFilterOperation="between"
                  defaultFilterValue={[
                    new Date(new Date().getFullYear() - 2, 1, 1),
                    new Date(),
                  ]}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="completionDate"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("completionDate")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column alignment="left" dataField="duration" caption={t("duration")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                {/* 
                           <Column dataField="tagNames"
                              caption={t("Tags")}>
                              <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
                           </Column> */}
                <Column
                  alignment="left"
                  dataField="labourCost"
                  caption={t("labourCost")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="materialCost"
                  caption={t("materialCost")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="equipmentCost"
                  caption={t("equipmentCost")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="otherCost"
                  caption={t("otherCost")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column alignment="left" dataField="total" caption={t("totalCost")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="note" caption={t("note")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="accountNo" caption={t("account")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="signRelated" caption={t("sign")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="supportRelated"
                  caption={t("support")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>
          </div>



          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"auto"}
            height={reportPopupHeight}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseReportModal()}
          >
            <TesReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={lstSelectedIds}
              isOpen={showReportModal}
            />
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Jobs;
