import { ContextMenu, DropDownButton, Popup } from "devextreme-react";
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Lookup,
    Pager, Paging,
    Search,
    Selection,
    SortByGroupSummaryInfo,
    StateStoring,
    Summary
} from 'devextreme-react/data-grid';
import ValidationGroup, { ValidationGroupRef } from "devextreme-react/validation-group";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CollisionDiagram from "../../../../../components/collisionDiagram/collisionDiagram";
import TesReportViewer from "../../../../../components/reportViewer/ReportViewer";
import reportLocations from "../../../../../constants/reportLocations";
import { useAuth } from "../../../../../contexts/auth";
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import { AuthApiUrl, CollisionApiUrl, FieldApiUrl } from "../../../../../environment/routeSettings";
import { ApprovalStatus, CollisionDataSourceType, GeoCodeStatus } from "../../../../../types/collision/enums/collisionEnums";
import { TableFieldRequestDTO } from "../../../../../types/field/dto/fieldDTO";
import { FieldCategoryType, FieldLocation2Show, FieldType } from "../../../../../types/field/enums/fieldEnums";
import { TesField } from '../../../../../types/field/fieldType';
import { GridType } from "../../../../../types/general/enums/generalEnums";
import { INameId, LazyLoadingRequest, NameValue } from "../../../../../types/general/generalTypes";
import { TesCodeValue } from "../../../../../types/infrastructure/infrastructureTypes";
import { ClientReport } from "../../../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../../../../utils/dateTimeTools";
import MaxDropdownItemWidthCalculator from "../../../../../utils/dropDownWidthCalculator";
import { Enum2Array, EnumFlag2Array } from "../../../../../utils/enumTools";
import { useScreenSize } from "../../../../../utils/media-query";
import { TesGet, TesPost } from '../../../../../utils/rest';
import { RightClickMenu } from "../../../../../utils/rightClickMenu";

// props
interface IPros {
    intersectionId: string
    isLocked: boolean;
    initDataReports: ClientReport[];
    validationRef: React.RefObject<ValidationGroupRef>;
    generalSettings: GeneralSetting | null
}

const Collision = (props: IPros) => {
    const [initDataFields, setInitDataFields] = useState<TesField[]>();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const history = useNavigate();
    const { activeLoading } = useAuth();
    const [initDataDivisions, setInitDataDivisions] = useState<INameId[]>([]);
    const [initDataTesCodeValues, setInitDataTesCodeValues] = useState<TesCodeValue>(new TesCodeValue());
    const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
    const [lstGeoCodeStatus, setLstGeoCodeStatus] = useState<NameValue[]>([]);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]); const [reportPopupHeight, setReportPopupHeight] = useState<string>("80%")
    const { is2xLarge } = useScreenSize();
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [lstCollisionDataSourceType, setLstCollisionDataSourceType] = useState<NameValue[]>([]);
    const [lstApprovalStatus, setLstApprovalStatus] = useState<NameValue[]>([]);
    const [lstOriginalSourceType, setLstOriginalSourceType] = useState<NameValue[]>([]);

    const items = [
        { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
        { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
    ];
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    useEffect(() => {
        if (is2xLarge) { setReportPopupHeight("55%") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitialDataFields();
                await getInitDataDivisions();
                await getInfrastructureTesCodeValues();
                setLstGeoCodeStatus(Enum2Array(GeoCodeStatus));
                setLstCollisionDataSourceType(Enum2Array(CollisionDataSourceType));
                setLstApprovalStatus(Enum2Array(ApprovalStatus));
                setLstOriginalSourceType(Enum2Array(CollisionDataSourceType));

                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getInitialDataFields() {
        var postOb: TableFieldRequestDTO = {
            customerId: localStorage.getItem('selectedCustomerId')!,
            categoryTypes: [FieldCategoryType.CollisionGeneral]
        }
        var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
        res = res.sort(function (a, b) {
            return (a.gridViewIndex - b.gridViewIndex)
          });
        setInitDataFields(res);
    }

    const dataSource = new CustomStore({
        key: "_id",
        load: async (loadOption) => {
            return await TesPost(
                `${CollisionApiUrl()
                }/api/Collisions/GetApprovedCollisionsOfLocation`,
                {
                    customerId: localStorage.getItem("selectedCustomerId"),
                    divisionId: localStorage.getItem("selectedDivisionId"),
                    loadOptions: loadOption,
                    filter: { Id: props.intersectionId }
                } as LazyLoadingRequest,
                true
            );
        },
    });


    async function getInitDataDivisions() {
        setInitDataDivisions(
            await TesGet(
                AuthApiUrl() +
                "/api/divisions/" +
                localStorage.getItem("selectedCustomerId"),
                true
            )
        );
    }

    async function getInfrastructureTesCodeValues() {
        setInitDataTesCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/infrastructureTesCodeValues/" + localStorage.getItem('selectedCustomerId'), true));
    }



    async function getCollisionDiagramData() {
        const data = await dataGridRef.current?.instance().getSelectedRowsData()
        if (data !== undefined) {
            return data.map(x => x._id) as string[]
        } else {
            notify(t("noSelectedRows"), "error", 5000);
            return []
        }
    }


    const navigateToCollisionDetails = useCallback(() => {
        history('/collision/collisionDetails/AddNew');
    }, [history]);

    function onRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
        if (e.data._id !== undefined) {

            if (isCtrlKeyPressed) {
                window.open(`/collision/collisionDetails/${e.data._id}`, "_blank");
            } else {
                history(`/collision/collisionDetails/${e.data._id}`);
            }
        }
    }

    async function onOpenReport(d: any) {
        var gridData = await dataGridRef.current?.instance().getSelectedRowsData();
        if (gridData?.length === 0 ?? true) {
            notify(t('pleaseSelectSomeData'), 'warning', 5000);
            return;
        }
        if (d !== null) {
            setSelectedReport(d.itemData);
            setSelectedIds(gridData!.map(x => x._id) as string[])
            setShowReportModal(true)
        }
    }

    function onCloseReportModal() {
        setShowReportModal(false);
        dataGridRef.current?.instance().deselectAll();
    }

    return (
        <div className={`intersectionDetails-collisions ${compactViewModel ? "compactStyle" : ""}`}>

            <React.Fragment>
                <ContextMenu
                    dataSource={items}
                    width={100}
                    target=".dx-data-row"
                    onItemClick={e => RightClickMenu(e, selectedRowData.row.data._id, "/collision/collisionDetails/", selectedRowData.row.data.LocationType, selectedRowData.row.data.LocationId)}
                />
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <div className={'content-block'}>
                        <div className={'dx-card responsive-paddings'}>
                            <div className="row">
                                <div className='leftColumn' style={{ display: "flex" }}>
                                    <CollisionDiagram
                                        getCollisionDiagramData={getCollisionDiagramData}
                                        lock={false}
                                    />

                                    <DropDownButton
                                        style={{ marginLeft: 15 }}
                                        icon="fa-solid fa-chart-line"
                                        hint={t('report')}
                                        items={SortObjectByPropName(props.initDataReports?.filter((x: ClientReport) => [reportLocations.Infrastructure_Details_Collision].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")}
                                        dropDownOptions={{ width: MaxDropdownItemWidthCalculator(SortObjectByPropName(props.initDataReports?.filter((x: ClientReport) => [reportLocations.Infrastructure_Details_Collision].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")) }}
                                        displayExpr="name"
                                        onItemClick={(d) => onOpenReport(d)}
                                    />
                                </div>
                                <div className='rightColumn'>
                                    <Button
                                        onClick={navigateToCollisionDetails}
                                        icon="fa-solid fa-circle-plus"
                                        text={t('add')}
                                        disabled={props.isLocked}
                                    />
                                </div>
                            </div>
                            {initDataFields && initDataFields.length > 0 && (
                                <DataGrid
                                    id="gridContainer"
                                    ref={dataGridRef}
                                    key="_id"
                                    dataSource={dataSource}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    onRowClick={onRowClicked}
                                    hoverStateEnabled={true}
                                    remoteOperations={true}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    onContextMenuPreparing={e => { setSelectedRowData(e) }}
                                    onExporting={OnExporting}
                                    //onContentReady={e => {e.component.deselectAll()}}
                                    //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                                    style={{ margin: "0 1rem" }}
                                >
                                    <Export enabled={true} allowExportSelectedData={true} />
                                    <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                                    <GroupPanel visible={true} /> {/* or "auto" */}
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={"top"} />
                                    <StateStoring
                                        enabled={true}
                                        type="localStorage"
                                        storageKey={GridType.CollisionsInIntersectionDetails.toString()}
                                        savingTimeout={500}
                                    />
                                    <SortByGroupSummaryInfo
                                        summaryItem="Total Count"
                                        sortOrder="desc"
                                    />
                                    <Summary>
                                        <GroupItem
                                            summaryType="count"
                                            alignByColumn
                                            name="Total Count"
                                        />
                                    </Summary>
                                    <Paging enabled={true} defaultPageSize={100} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[100, 200, 300, 400, 500]}
                                        showNavigationButtons={true}
                                        showInfo={true}
                                        visible={true}
                                    />
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                        deferred={true}
                                        // @ts-ignore
                                        maxFilterLengthInRequest={10000}
                                    />
                                    <FilterRow visible={true} applyFilter="auto" />
                                    <HeaderFilter visible={true} />
                                    {/* <SearchPanel visible={true} width={285} placeholder={t("search...")} /> */}
                                    <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                                        <Search enabled />
                                    </ColumnChooser>
                                    <Column dataField="_id" caption={t("id")} visible={false}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="GeoId" allowSorting={true} caption={t("geoId")}
                                    >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column
                                        dataField="LocationDescription"
                                        allowHeaderFiltering={false}
                                        width={300}
                                        caption={t("locationDescription")}
                                    >
                                        <Search enabled />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column
                                        dataField="MapLocation.Latitude"
                                        allowSorting={true}
                                        allowHeaderFiltering={false}
                                        caption={t("latitude")}
                                        format={{ type: 'fixedPoint', precision: 4 }}
                                        visible={false}
                                    >
                                        <Search enabled />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column
                                        dataField="MapLocation.Longitude"
                                        allowSorting={true}
                                        allowHeaderFiltering={false}
                                        caption={t("longitude")}
                                        format={{ type: 'fixedPoint', precision: 4 }}
                                        visible={false}
                                    >
                                        <Search enabled />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column dataField="GeoCodeStatus" caption={t("geoCodeStatus")} visible={false}>
                                        <Lookup
                                            dataSource={SortObjectByPropName(lstGeoCodeStatus, "name")}
                                            valueExpr="value"
                                            displayExpr="name"
                                        />
                                    </Column>

                                    <Column dataField="EditedSubmissionDT" caption={t("lastEditDT")} visible={false}
                                        dataType="datetime"
                                        format={props.generalSettings?.dateFormat}
                                    >
                                    </Column>

                                    <Column
                                        dataField="SubmissionDT"
                                        caption={t('submissionDT')}
                                        dataType="datetime"
                                        visible={false}
                                        format={props.generalSettings?.dateTimeFormat}
                                    >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>


                                    <Column dataField="DataSourceType" caption={t("dataSourceType")} visible={false}>
                                        <Lookup
                                            dataSource={SortObjectByPropName(lstCollisionDataSourceType, "name")}
                                            valueExpr="value"
                                            displayExpr="name"
                                        />
                                    </Column>
                                    {initDataTesCodeValues.municipalities?.length !== 0 && initDataTesCodeValues.municipalities !== undefined &&

                                        <Column
                                            dataField="MunicipalityId"
                                            allowHeaderFiltering={true}
                                            caption={t("municipality")}
                                        >
                                            <Search enabled />
                                            <Lookup
                                                dataSource={SortObjectByPropName(initDataTesCodeValues.municipalities, "name")}
                                                valueExpr="id"
                                                displayExpr="name"
                                            />
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    }

                                    {initDataTesCodeValues.jurisdictions?.length !== 0 && initDataTesCodeValues.jurisdictions !== undefined &&

                                        <Column
                                            dataField="JurisdictionId"
                                            allowHeaderFiltering={true}
                                            caption={t("jurisdiction")}
                                        >
                                            <Search enabled />
                                            <Lookup
                                                dataSource={SortObjectByPropName(initDataTesCodeValues.jurisdictions, "name")}
                                                valueExpr="id"
                                                displayExpr="name"
                                            />
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    }

                                    <Column
                                        dataField="Year"
                                        dataType="number"
                                        alignment="left"
                                        sortOrder={'desc'}
                                        allowHeaderFiltering={true}
                                        caption={t("accidentYear")}
                                    >
                                        <Search enabled />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column
                                        dataField="ApproveLevel"
                                        caption={t('approveLevel')}
                                        visible={false}
                                    >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column dataField="ApprovalStatus" caption={t("approvalStatus")} visible={false}>
                                        <Lookup
                                            dataSource={SortObjectByPropName(lstApprovalStatus, "name")}
                                            valueExpr="value"
                                            displayExpr="name"
                                        />
                                    </Column>

                                    <Column dataField="GeoCodeGroupId" caption={t("geoCodeGroupId")} visible={false}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column dataField="LocationId" caption={t("locationId")} visible={false}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column dataField="OriginalSourcetype" caption={t("originalSourceType")} visible={false}>
                                        <Lookup
                                            dataSource={SortObjectByPropName(lstOriginalSourceType, "name")}
                                            valueExpr="value"
                                            displayExpr="name"
                                        />
                                    </Column>

                                    {(initDataDivisions && initDataDivisions.length > 0) &&
                                        <Column dataField="DivisionId" caption={t("division")}>
                                            <Lookup
                                                dataSource={SortObjectByPropName(initDataDivisions, "name")}
                                                valueExpr="id"
                                                displayExpr="name"
                                            />
                                        </Column>
                                    }
                                    {initDataFields.map((d) => {
                                        if (d.fieldType === FieldType.List) {
                                            return (
                                                <Column
                                                    key={d.name}
                                                    dataField={d.name}
                                                    caption={d.labelText}
                                                    allowFiltering={true}
                                                    visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}

                                                >
                                                    <Search enabled />
                                                    <Lookup
                                                        dataSource={SortObjectByPropName(d.fieldValues, "name")}
                                                        valueExpr="id"
                                                        displayExpr="name"
                                                    />
                                                    <HeaderFilter>
                                                        <Search enabled />
                                                    </HeaderFilter>
                                                </Column>
                                            );
                                        } else if (d.fieldType === FieldType.Number) {
                                            return (
                                                <Column
                                                    key={d.name}
                                                    dataField={d.name}
                                                    caption={d.labelText}
                                                    dataType="number"
                                                    alignment="left"
                                                    visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}                      >
                                                    <HeaderFilter>
                                                        <Search enabled />
                                                    </HeaderFilter>
                                                </Column>
                                            );
                                        } else if (d.fieldType === FieldType.Date) {
                                            return (
                                                <Column
                                                    key={d.name}
                                                    dataField={d.name}
                                                    caption={d.labelText}
                                                    dataType="date"
                                                    calculateCellValue={(e: any) => GridActualDateCalculator(e, d, props.generalSettings)}
                                                    format={props.generalSettings?.dateFormat}
                                                    visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}                      >
                                                    <HeaderFilter>
                                                        <Search enabled />
                                                    </HeaderFilter>
                                                </Column>
                                            );
                                        } else if (d.fieldType === FieldType.Time) {
                                            return (
                                                <Column
                                                    key={d.name}
                                                    dataField={d.name}
                                                    caption={d.labelText}
                                                    dataType="datetime"
                                                    calculateCellValue={(e: any) => GridActualDateCalculator(e, d, props.generalSettings)}
                                                    format={props.generalSettings?.timeFormat}
                                                    visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}                      >
                                                    <HeaderFilter>
                                                        <Search enabled />
                                                    </HeaderFilter>
                                                </Column>
                                            );
                                        } else if (d.fieldType === FieldType.DateTime) {
                                            return (
                                                <Column
                                                    key={d.name}
                                                    dataField={d.name}
                                                    caption={d.labelText}
                                                    dataType="datetime"
                                                    calculateCellValue={(e: any) => GridActualDateCalculator(e, d, props.generalSettings)}
                                                    format={props.generalSettings?.dateTimeFormat}
                                                    visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}                      >
                                                    <HeaderFilter>
                                                        <Search enabled />
                                                    </HeaderFilter>
                                                </Column>
                                            );
                                        } else if (d.fieldType === FieldType.Boolean) {
                                            return (
                                                <Column
                                                    key={d.name}
                                                    dataField={d.name}
                                                    caption={d.labelText}
                                                    dataType="boolean"
                                                    visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}                      >
                                                    <HeaderFilter>
                                                        <Search enabled />
                                                    </HeaderFilter>
                                                </Column>
                                            );
                                        } else {
                                            return (
                                                <Column
                                                    key={d.name}
                                                    dataField={d.name}
                                                    caption={d.labelText}
                                                    allowHeaderFiltering={false}
                                                    visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}                      >
                                                    <Search enabled />
                                                    <HeaderFilter>
                                                        <Search enabled />
                                                    </HeaderFilter>

                                                </Column>
                                            );
                                        }
                                    })}
                                </DataGrid>
                            )}
                        </div>
                    </div>

                    {/* Report Popup */}
                    <Popup
                        visible={showReportModal}
                        width={"auto"}
                        height={reportPopupHeight}
                        resizeEnabled={true}
                        showTitle={true}
                        title={t("reportViewer")}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => onCloseReportModal()}
                    >
                        <TesReportViewer
                            selectedReport={selectedReport}
                            lstSelectedIds={selectedIds}
                            isOpen={showReportModal}
                        />
                    </Popup>
                </ValidationGroup>
            </React.Fragment>
        </div>
    );
}
export default Collision;