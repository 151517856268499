//#region imports
import { ContextMenu } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import TextBox from "devextreme-react/text-box";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from "../../../../components/gisMap/generalGisMapManager";
import GridProfilePicker from "../../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../../components/permission/permision";
import TesReportViewer from "../../../../components/reportViewer/ReportViewer";
import getTitle from "../../../../components/title/getTitle";
import { CollisionPermissions } from "../../../../constants/Permissions";
import reportSettings from "../../../../constants/report-settings";
import reportLocations from "../../../../constants/reportLocations";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import { AuthApiUrl, CollisionApiUrl, FieldApiUrl, InfrastructureApiUrl, ReportApiUrl } from "../../../../environment/routeSettings";
import { InvolvedAsType } from "../../../../types/collision/enums/collisionEnums";
import { TableFieldRequestDTO } from "../../../../types/field/dto/fieldDTO";
import { FieldCategoryType, FieldLocation2Show, FieldType } from "../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../types/field/fieldType";
import { GridType, ResponseCode, TesMapModule } from "../../../../types/general/enums/generalEnums";
import { GridSetting, INameId, LazyLoadingRequest, RequestResponseResult } from "../../../../types/general/generalTypes";
import {
  Area
} from "../../../../types/infrastructure/infrastructureTypes";
import { ClientReport, UiReportRequest } from "../../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../../../utils/dateTimeTools";
import MaxDropdownItemWidthCalculator from "../../../../utils/dropDownWidthCalculator";
import { EnumFlag2Array } from "../../../../utils/enumTools";
import { useScreenSize } from "../../../../utils/media-query";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import "./peopleInvolved.scss";
//#endregion imports


const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${CollisionApiUrl()
      }/api/Collisions/GetPeopleInvolved/`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
        filter: {
          type: InvolvedAsType.Pedestrian
        }
      } as LazyLoadingRequest,
      true
    );
  },
});

const Pedestrians = () => {
  //#region consts
  const { activeLoading } = useAuth();
  const [showMap, setShowMap] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [initDataDivisions, setInitDataDivisions] = useState<INameId[]>([]);
  const [initDataSiteList, setInitDataSiteList] = useState<Area[]>([]);
  const [initDataReports, setInitDataReports] = useState<ClientReport[]>([]);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [lstSelectedIds, setLstSelectedIds] = useState<string[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { generalSetting, customerDivisions } = useClientSetting();
  const { t } = useTranslation();
  const dropDownOptions = { width: 215, };
  const { is2xLarge } = useScreenSize();
  const [reportPopupHeight, setReportPopupHeight] = useState<string>("80%")
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  //#endregion consts


  //#region functions

  const title = getTitle('/collision/peopleInvolved', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (is2xLarge) { setReportPopupHeight("55%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        await getInitDataDivisions();
        await getInitDataSiteList();
        await getInitialDataReports();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigateToCollisionDetails = useCallback(() => {
    history("/collision/collisionDetails/AddNew");
  }, [history]);
  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.CollisionPerson, FieldCategoryType.CollisionPedestrian]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(function (a, b) {
      return (a.gridViewIndex - b.gridViewIndex)
    });
    setInitDataFields(res);
  }

  async function getInitDataDivisions() {
    setInitDataDivisions(
      await TesGet(
        AuthApiUrl() +
        "/api/divisions/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitDataSiteList() {
    const postObj = {
      customerId: localStorage.getItem("selectedCustomerId"),
      divisionId: localStorage.getItem("selectedDivisionId"),
    }
    const res = await TesPost(InfrastructureApiUrl() + "/api/areas",
      postObj
      , true) as RequestResponseResult<Area[]>
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteList(res.results)
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitialDataReports() {
    try {
      var postObj: UiReportRequest = {
        customerId: localStorage.getItem("selectedCustomerId")!,
        lstLocations: [
          reportLocations.Collision_Collisions,
        ]
      }

      var res: ClientReport[] = await TesPost(
        ReportApiUrl() +
        "/api/ClientReports/GetPageReports",
        postObj,
        true
      );

      setInitDataReports(res);
    } catch (ex) {
      notify(t("errorInFetchReports") + ex, "error", 5000);
    }
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data._id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/collision/collisionDetails/${e.data._id}`, "_blank");
      } else {
        history(`/collision/collisionDetails/${e.data._id}`);
      }
    }
  }

  function onCloseFilterModal() {
    setShowFilter(false);
  }

  function onApplyFilter() { }

  async function onOpenReport(d: any) {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
    if (gridData?.length === 0 ?? true) {
      notify(t('pleaseSelectSomeData'), 'warning', 5000);
      return;
    }
    if ((gridData?.length ?? 0) > reportSettings.noMaxItems) {
      notify(t('selectCorrectNumberOfGrid'), 'warning', 5000);
      return;
    }
    if (d !== null) {
      setSelectedReport(d.itemData);
    }
    setShowReportModal(true)
    setLstSelectedIds(gridData!.map(x => x) as string[])
  }

  function onCloseReportModal() {
    setShowReportModal(false);
    dataGridRef.current?.instance().deselectAll();
  }

  //#endregion functions
  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_V,
        CollisionPermissions.Collision_V_MyCollision,
        CollisionPermissions.Collision_D,
        CollisionPermissions.Collision_E,
        CollisionPermissions.Collision_Approve,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.data._id, "/collision/collisionDetails/", selectedRowData.row.data.LocationType, selectedRowData.row.data.LocationId)}
          />
          <h2 className={"content-block"}>{t("pedestrians")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>
                  <Button
                    onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                    style={{ marginRight: 10 }}
                    hint={t("showMap")}
                  />

                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.CollisionPeopleInvolved}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />
                  <DropDownButton
                    style={{ marginLeft: 10 }}
                    icon="fa-solid fa-chart-line"
                    hint={t('report')}
                    items={SortObjectByPropName(initDataReports?.filter((x: ClientReport) => [reportLocations.Collision_Collisions].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")}
                    dropDownOptions={{ width: MaxDropdownItemWidthCalculator(SortObjectByPropName(initDataReports?.filter((x: ClientReport) => [reportLocations.Collision_Collisions].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)), "name")) }}
                    displayExpr="name"
                    onItemClick={(d: any) => onOpenReport(d)}
                  />

                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[CollisionPermissions.Collision_D]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={navigateToCollisionDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("collision")}
                    />
                  </Permission>
                </div>
              </div>
              {initDataFields && initDataFields.length > 0 && (
                <DataGrid
                  id="gridContainer"
                  ref={dataGridRef}
                  dataSource={dataSource}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  onRowClick={onRowClicked}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  style={{ margin: "0 1rem" }}
                  onExporting={OnExporting}
                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                  columnAutoWidth={gridSetting.horizontalScrolling}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={GridType.CollisionPedestrian.toString()}
                    savingTimeout={500}
                  />
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    width={285}
                    placeholder={t("search...")}
                  />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column dataField="Id" caption={"id"} visible={false}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField="AccNumber" caption={t("accNumber")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField="SimplifiedCollision.AccidentDate" caption={t("accidentDate")}
                    dataType="datetime"
                    format={generalSetting?.dateTimeFormat}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField="GeoId" caption={t("geoId")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationDescription"
                    allowSorting={false}
                    width={300}
                    caption={t("locationDescription")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="MapLocation.Latitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("latitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="MapLocation.Longitude"
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={t("longitude")}
                    format={{ type: 'fixedPoint', precision: 4 }}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  {initDataFields.filter(x => (x.fieldCategoryType === FieldCategoryType.CollisionPerson && x.isShowInPedestrian) || x.fieldCategoryType === FieldCategoryType.CollisionPedestrian).map((d) => {
                    if (d.fieldType === FieldType.List) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          allowFiltering={true}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <Search enabled />
                          <Lookup
                            dataSource={SortObjectByPropName(d.fieldValues, "name")}
                            valueExpr="id"
                            displayExpr="name"
                          />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Number) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="number"
                          alignment="left"
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Date) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="date"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.dateFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Time) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.timeFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.DateTime) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                          format={generalSetting?.dateTimeFormat}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Boolean) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="boolean"
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          allowHeaderFiltering={false}
                          visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                        >
                          <Search enabled />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>

                        </Column>
                      );
                    }
                  })}
                  <Column
                    dataField="DivisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(customerDivisions!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                </DataGrid>
              )}
            </div>
          </div>

          {/* Filter Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showFilter}
            resizeEnabled={true}
            showTitle={true}
            title={t("filter")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={onCloseFilterModal}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("name")}
                    labelMode="static"
                    //value={selectedDataCodeValue.name}
                    //onValueChange={e => onValueChangedCodeValue("name", e)}
                    className="modalInput"
                  />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => setShowFilter(false)}
                    text={t("cancel")}
                  />

                  <Button
                    className="tes-modal-btn-add"
                    onClick={onApplyFilter}
                    text={t("apply")}
                  />
                </div>
              </div>
            </ScrollView>
          </Popup>

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Collision}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="LocationId"
            />
          </Popup>
          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"60%"}
            height={reportPopupHeight}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseReportModal()}
          >
            <TesReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={lstSelectedIds}
              isOpen={showReportModal}
            />
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Pedestrians;
